<template>
<div>
	<el-dialog title="上传本地数据" :visible.sync='dialogImportVisible' width="51%" style="min-height: 200px;" :close-on-click-modal="false"> 
	<div class="import-box">
		<div>1、选择需要导入的资产卡片类型：
			<el-select v-model="uploadParams.card_id" style="width:150px;" placeholder="请选择资产卡片"  clearable @change="changeCard">
	            <el-option v-for="(item,index) in cardData" :key="index"  :value="item.id"  :label="item.name"></el-option>
	         </el-select>
		</div>
		<div>2、下载对应模板： <a style="color: #409EFF;" @click="download">{{cardName}}</a></span></div>
		<!-- <div>
			<el-upload
			   class="upload-demo"
			   ref="upload"
			   :action="$uploadUrl"
			   :multiple="false"
			   :on-change="handleUpdateChange"
			   :on-success="handleUpdateSuccess"
			   :file-list="tempFileList"
			   :auto-upload="true"
			 >
			   <el-button v-if="roleId == 1" slot="trigger" size="small" type="primary" style="margin-right:10px;">更新</el-button>
			</el-upload>
		</div> -->
		<div style="display: flex;">
			3、选择需要导入的数据文件（xls、xlsx 格式）
			<el-upload
			  class="upload-demo"
			  :data="uploadParams"
			  ref="uploadCseFile"
			  :action="uploadUrl"
			  :multiple="false"
			  :on-change="handleChange"
			  :on-success="handleImportSuccess"
			  :auto-upload="true"
			>
			<el-button type="primary" size="small" plain class="browse-btn">浏览</el-button> 
			</el-upload>
		</div>

	</div>

	<el-table
		v-if="tableStatus"
		class="storage-table-box"
		border
		height="400"
		:header-cell-style="{height:'35px',background: '#F2F6FC',padding: '0',fontSize:'12px'}"
		:row-style="{height:'40px'}"
		 :cell-style="{padding: '0'}" 
		:data="tableData" 
		style="width: 100%;"
		:virtual-scroll="true"
		:virtual-height="virtualHeight"  
		 @scroll="handleScroll"  
		 >
		<template v-for="(item,thIndex) in theadData">
			<el-table-column
			v-if="thIndex == 0"
			width="80px"
			>
			<template slot-scope="scope">
				{{scope.$index + 1}}
			</template>
			</el-table-column>
			<el-table-column
			  :style="item.err_status?{border:'1px solid red'}:''"
			  width="120px"
			 :key="item.field_name" :label="item.label">
				<template slot-scope="scope">
					<div class="add-table-row-box" 
						:style="[{border:(item.required_status == 1 
							&& ( typeof  scope.row[item.field_name]==='undefined' || scope.row[item.field_name]==null || scope.row[item.field_name] === '') 
							&& item.field_name != 'asset_code')?'1px solid red':'none'}]">
						<el-input  v-if="(item.type == 0 || item.type == 1)&& item.field_name != 'asset_code'"
							v-model="scope.row[item.field_name]" 
							:placeholder="item.label" ></el-input>
						<el-input 
							 v-if="item.type == 0 && item.field_name == 'asset_code'"
							v-model="scope.row[item.field_name]" 
							:placeholder="assetCodeStatus?'自动编码':item.label" :disabled="assetCodeStatus"></el-input>	

						<el-input-number 
							v-if="item.type == 11 " 
							v-model="scope.row[item.field_name]" 
							:precision="2" :step="0.1" :min="0" 
							 controls-position="right">
							 </el-input-number>
						 <el-input-number
							v-if="item.type == 12 " 
							v-model="scope.row[item.field_name]" 
							 :min="0" 
							 controls-position="right">
							 </el-input-number>
						<el-date-picker
							v-if="item.type == 6"
							v-model="scope.row[item.field_name]"
							type="date"
							placeholder="选择日期">
						</el-date-picker>
						<template  v-if="item.type == 9">
							<el-select  v-if="item.field_name == 'location_id'"  ref="treeSelect"
								v-model="scope.row['location_name']" placeholder="请选择" clearable 
							
								 @change="(val)=>handleSelect(val,index,scope.$index)">
									<el-option value="0" label="无上级">无上级</el-option>
									<el-tree   node-key="id" :data="locationTreeData" :props="treePorps"
									 highlight-current ref="locationTree" 
									 :default-expanded-keys="[scope.row['location_id']]"
									 @node-click="(val)=>handleNodeClick(val,scope.$index,'location_id')"> </el-tree>
							</el-select>
							<el-select  v-if="item.field_name == 'cate_id'"  ref="treeSelect"
								v-model="scope.row['cate_name']" placeholder="请选择" clearable 
								 @change="(val)=>handleSelect(val,index,childIndex)">
									<el-option value="0" label="无上级">无上级</el-option>
									<el-tree   node-key="id" :data="cateTreeData" :props="treePorps"
									 highlight-current ref="cateTree" 
									 :default-expanded-keys="[scope.row['cate_id']]"
									 @node-click="(val)=>handleNodeClick(val,scope.$index,'cate_id')"> </el-tree>
							</el-select>
							<el-cascader
								 v-if="item.field_name == 'dept_id'"
								v-model="scope.row[item.field_name]"   
								 :props="props"  
								 :options="deptList"  
								  @change="handleChangeDept"
								  :show-all-levels="false"
								  ref="cascader"  
								></el-cascader> 
							<!-- 部门成员 -->
							<el-select filterable 
									v-else-if="item.field_name == 'user_id'" 
									v-model="scope.row[item.field_name]"   
									placeholder="请选择" 
									 style="width: 280px;">
									 <el-option
									  v-for="admin in adminList"
									 :label="admin.name"
									 :value="admin.id">
									 </el-option>
							</el-select>
							<el-select v-if="item.field_name !== 'cate_id' 
								&& item.field_name !== 'location_id'
								&& item.field_name !== 'dept_id'
								&& item.field_name !== 'user_id'"
								v-model="scope.row[item.field_name]"   placeholder="请选择"  style="width: 120px;">
								<el-option v-for="(sItem,sIndex) in item.items" :label="sItem['name']" :value="sIndex"></el-option>
							</el-select>
						</template>
					</div> 
					<!-- <span>{{scope.row[item.field_name]}}</span> -->
				</template>
			</el-table-column>
		</template>
	</el-table> 
		<div slot="footer" class="dialog-footer">
			<el-button @click="dialogImportVisible = false">取 消</el-button>
			<el-button type="primary" @click="confirm()">确 定</el-button>
		</div>
	</el-dialog>
</div>
</template>

<script>
export default {
	inject:['reload'],
    data() {
        return {
			virtualHeight:200,
			dialogImportVisible:false,
			uploadUrl:'/api/admin/AssetList/import', 	
			cardData:[],
			uploadParams:{
			    card_id:'', 
			},
			assetCodeStatus:false,
			tableData:[],
			tableStatus:false, 
			theadData:[],
			treePorps:{
				label:'name'
			},
			locationTreeData:[],
			cateTreeData:[],
			roleId:localStorage.getItem('role_id'),     //当前登录角色
			tempFileList:[],
			importTempUrl:'',
			cardName:'请先选择卡片',
			deptList:[],
			adminList:[],
			props:{
				value:'id',
				label:'name',
				checkStrictly:true
			},
        }
    },
    created() {

    },
    mounted() {

    },
    methods:{
		getData(){
			this.$api.post("AssetCard/index", null, res => {
			    if (res.code == 200) {
			        this.cardData =  res.data.list
					this.dialogImportVisible = true
					
			    }
			});
		},
		handleChange(file,fileList){
			this.$emit('handleBeforeUploadFile',file,fileList,true,res=>{
				this.fileList =  res
			})
		},
		handleScroll(event) {
		     // 当表格容器滚动时，根据滚动位置更新可视区域内的行数据。  
		     const scrollTop = event.target.scrollTop;  
		     const clientHeight = event.target.clientHeight;  
		     const totalHeight = event.target.scrollHeight;  
		     const startIndex = Math.floor(scrollTop / clientHeight); // 计算新的起始索引  
		     const endIndex = Math.min(startIndex + this.virtualHeight / clientHeight, this.tableData.length); // 计算新的结束索引，确保不超出数据范围。  
		     this.startIndex = startIndex; // 更新起始索引，以便下次滚动时获取下一块数据。  
		     this.tableData = this.tableData.slice(startIndex, endIndex); // 根据新的起始索引和结束索引更新表格数据。  
		   },  
		
		//部门成员联动
		handleChangeDept(value){
			let dept_id = value[value.length-1] 
			 this.$api.get('admin/getDeptAdmin',{dept_id:dept_id},res=>{
				  if(res.code == 200){
						this.adminList =  res.data.list
				  }else{
					  this.$message.error(res.msg)
				  }
			  })
		},
		
		//监听卡片选择
		changeCard(val){
			if(!val){
				this.$message.error('请选择资产卡片类型')
				return false
			}
			let opt = {}
			 opt = this.cardData.find((item)=>{
				return item.id  === val
			})
			this.cardName  = opt.name + '导入模板-excel文件'
			console.log(opt)
			// this.$api.get('AssetList/importTemplate', null, res => {
			// 	if (res.code == 200) {
			// 		this.importTempUrl =  res.data.url
			// 	} else {
			// 		this.$message.error(res.msg)
			// 	}
			// })
		},
		
		//导入数据 
		confirm(){
		
			this.$api.post('AssetList/setImport', {
				table_data:this.tableData,
				card_id:this.uploadParams.card_id
			}, res => {
				const loading = this.$loading({
				      lock: true,
				      text: '数据导入中，请勿关闭窗口……',
				      spinner: 'el-icon-loading',
				      background: 'rgba(0, 0, 0, 0.7)'
				});
				if (res.code == 200) {
					this.dialogImportVisible = false
					this.$message.success(res.msg)
					this.reload(); 
					loading.close()
				} else {
					this.$message.error(res.msg)
					loading.close()
				}
				
			})
		},
		handleUpdateChange(file,fileList){
			
			 this.tempFileList =  this.$emit('handleBeforeUploadFile',file,fileList)
			 console.log('temp:', this.tempFileList)
		},
		//更新下载模板
		handleUpdateSuccess(res){
			if(res.code == 200){
			   this.$api.put('SysConfig/updateExcelTemplate',{
					 name:'asset_template',
					 url:res.data.url
			   },res2=>{ 
				 if(res2.code == 200){
					 this.$message.success(res2.msg)
					 this.reload()
				 }else{
					 this.$message.error(res.msg)
				 }
			   })
			     this.reload()
			 }else{
			     this.$message.error(res.msg)
			 }
		},
		download(){
			location.href = '/api/admin/AssetList/downTemplate?card_id='+this.uploadParams.card_id
		},
		
		handleImportSuccess(res){
			this.$refs.uploadCseFile.clearFiles()
		    if(res.code == 200){
				//获取资产位置和资产分类
				
				this.$nextTick(()=>{
					this.tableStatus = true
					this.theadData =  res.data.thead
					this.assetCodeStatus =  res.data.asset_code_status == 1?true:false
					this.tableData =  res.data.table_list
					this.deptList =  res.data.dept_list
					this.adminList = res.data.admin_list
					this.$api.get('AssetCategory/treeData',null,res=>{
						this.tableStatus = false
						if(res.code == 200){
							this.tableStatus = true
							this.cateTreeData =  res.data.tree_list
							this.$nextTick(()=>{
								this.tableData.forEach((item,index)=>{
									this.$refs.cateTree[index].setCurrentKey(item['cate_id'])
								})
							})
						} 
					})
					this.$api.get('AssetLocation/treeData',null,res=>{
						if(res.code == 200){
							this.locationTreeData =  res.data.tree_list
							this.$nextTick(()=>{
								this.tableData.forEach((item,index)=>{
									this.$refs.locationTree[index].setCurrentKey(item['location_id'])
								})								
							})
						}
					})
				})
		     }else{
		         this.$message.error(res.msg)
		     }
		},
		handleNodeClick(val,index,name){
			this.tableStatus = false
			this.$nextTick(()=>{
					this.tableStatus = true
					if(name == 'location_id'){
						this.tableData[index]['location_name'] = val.name
						this.tableData[index]['location_id'] = val.id
					}
					if(name == 'cate_id'){
						this.tableData[index]['cate_name'] = val.name
						this.tableData[index]['cate_id'] = val.id
					}
			})
		}
		
    },
}
</script>
<style scoped lang="scss">
	.import-box{
		div{
			height: 45px;
			line-height: 45px;
			// display: flex;
			.browse-btn{
				width: 120px;
			}
		}
	}
	
</style>