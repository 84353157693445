<template>
	<div class="page-container">
		<p class="main-title">{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
		<div class="page-desc">
			资产清单是系统的台账，展示系统管理的所有资产和资产信息，以及对资产进行快捷操作的入口
		</div>
		
		<div class="search-form" >
			<el-form ref="form" :inline="true" :model="form" prop="">
				<el-form-item label="">
					<el-input v-model="form.name" placeholder="请输入关键字搜索" style="width:200px;"></el-input>
				</el-form-item>
				<el-button type="primary" @click="getData()">查询</el-button>
				<el-button  type="primary" @click="handleSearch" style="margin-left: 16px;">
			  高级搜索
				</el-button>
				 <el-button type="primary" @click="handleExport()" plain style="background: white;">导出全部</el-button>
				<el-button type="primary" @click="handleImport()" plain style="background: white;">批量导入</el-button> 
				
			</el-form>
		</div>
		<template v-if="!batchStatus">
		<div style="margin-bottom:10px;display: flex;">
			<el-button size="small" type="" @click="handleAdd()">添加</el-button>
			<div style="font-size: 12px;color: #606266;position: absolute;right: 20px;">
				<div style="position: relative;">
					<span @click="handleColumn">
						<i class="el-icon el-icon-setting"></i>显示列
					</span>
					<div class="floating-box" v-if="fieldFloatingStatus">
						<el-tree :data="data" show-checkbox node-key="field_name" ref="rowTree" @check-change="handleTreeCheck"
							@node-drop="handleDrop" default-expand-all draggable
							:default-checked-keys="defaultCheckedKeys" :allow-drop="allowDrop">
							<span class="custom-tree-node" slot-scope="{ node, data }">
								<span>{{node.label}}</span>
								<span><i class="el-icon-sort"></i></span>
							</span>
						</el-tree>
					</div>
				</div>
			</div>
		</div>
		</template>
		<div v-if="batchStatus" style="margin-bottom: 10px;">
			<el-button class="btn" size="small" @click="handleCommonAdd(multipleData,2,'资产派发单','')">派发</el-button>
			<el-button class="btn" size="small" @click="handleCommonAdd(multipleData,3,'资产退库单')">退库</el-button>
			<el-button  class="btn"   size="small"  plain  @click="handleCommonAdd(multipleData,4,'资产借出单')">借出</el-button>
			<el-button  class="btn"   size="small"  plain  @click="handleCommonAdd(multipleData,5,'资产归还单')">归还</el-button>
			<el-button  class="btn"  size="small"  plain  @click="handleCommonAdd(multipleData,6,'变更领用人')">变更</el-button>
			<el-button  class="btn"  size="small" plain   @click="handleExportLabel()">导出标签</el-button>
			<el-button  class="btn"  size="small" type="danger" plain  @click="handleDel(multipleData)">删除</el-button>
		</div>
		<!-- :header-cell-style="{background:'#f5f7fa','border-top':'1px solid #e6e6e6','border-right':'1px solid #e6e6e6'}" -->
		<el-table 
			v-loading="loading"
			element-loading-text="查询中"
			row-key="asset_code"
			@selection-change="handleSelectionChange" 
			 :data="tableData" style="width:100%;min-height: 500px;">
			<el-table-column
			type="selection"
			:reserve-selection="true"
			width="55">
			</el-table-column>
			<template v-for="item in theadData">
				<el-table-column :width="item.width" :key="item.field_name" :label="item.label">
					<template slot-scope="scope">
						<div v-if="item.field_name == 'status'" >
							<el-tag type="success" size='mini' v-if="scope.row[item.field_name] == 1">空闲</el-tag>
							<el-tag  size='mini' v-if="scope.row[item.field_name] == 2">派发中</el-tag>
							<el-tag type="warning" size='mini' v-if="scope.row[item.field_name] == 3">退库中</el-tag>
							<el-tag type="danger" size='mini' v-if="scope.row[item.field_name] == 4">出借中</el-tag>
							<el-tag type="warning" size='mini' v-if="scope.row[item.field_name] == 5">归还中</el-tag>
							<el-tag size='mini' v-if="scope.row[item.field_name] == 6">变更中</el-tag>
							<el-tag type="danger" size='mini' v-if="scope.row[item.field_name] == 7">调拨中</el-tag>
							<el-tag type="danger" size='mini'  effect="dark"  v-if="scope.row[item.field_name] == 8">维修中</el-tag>
							<el-tag size='mini'  v-if="scope.row[item.field_name] == 9" type="warning">处置中</el-tag>
							<el-tag size='mini' v-if="scope.row[item.field_name] == 10">修改中</el-tag>
							<el-tag size='mini' type="danger" v-if="scope.row[item.field_name] == 11">已报失</el-tag>
							<el-tag size='mini' v-if="scope.row[item.field_name] == 12">在用</el-tag>
						</div>
						<div v-if="item.type == 4  && scope.row[item.field_name] !== '-'">
							<el-image style="width: 35px;height: 35px;" v-for="(imgItem,imgIndex) in scope.row[item.field_name]" :src="imgItem"></el-image>
						</div> 
						<template v-if="item.field_name !== 'status' && item.type != 4">
							<span v-if="item.field_name == 'cate_id'">{{scope.row.cate_name}}</span>
							<span v-if="item.field_name == 'location_id'">{{scope.row.location_name}}</span>
							<span v-if="item.field_name !== 'cate_id' && item.field_name !== 'location_id'">{{scope.row[item.field_name]?scope.row[item.field_name]:'-'}}</span>
						</template>
					</template>
				</el-table-column>
			</template>
			<el-table-column fixed="right" width="200px" label="操作">
				<template slot-scope="scope">
					<el-button class="btn" size="small" @click="handleCommonAdd(scope.row,2,'资产派发单')">派发</el-button>
					<el-button class="btn" size="small" @click="handleCommonAdd(scope.row,3,'资产退库单')">退库</el-button>
					
					<el-button class="btn" size="small" @click="showMore(scope.$index,tableData)"><i
							class="el-icon-more"></i></el-button>
					<template v-if="scope.row.more_status">
						<div class="more-box">
							<el-button  class="btn"   size="small"  plain  @click="handleCommonAdd(scope.row,4,'资产借出单')">借出</el-button>
							<el-button  class="btn"   size="small"  plain  @click="handleCommonAdd(scope.row,5,'资产归还单')">归还</el-button>
							<el-button  class="btn"  size="small"  plain  @click="handleCommonAdd(scope.row,6,'变更领用人')">变更</el-button>
							<el-button  class="btn"  size="small"  plain  @click="handleAdd(scope.row.id,scope.row)">修改</el-button>
							<el-button  class="btn"  size="small"  plain  @click="handleCommonAdd(scope.row,7,'资产调拨单')">调拨</el-button>
							<el-button  class="btn"  size="small" type="danger" plain  @click="handleDel(scope.row)">删除</el-button>
						</div>
					</template> 
				</template>
			</el-table-column>
		</el-table>
		<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view>
		<AddVue ref="childDialog"></AddVue>
		<!-- <SearchVue  :cateTreeData="cateTreeData" :locationTreeData="locationTreeData" v-if="Object.keys(cateTreeData).length > 0"></SearchVue> -->
		<SearchVue  ref="searchDialog" :sform.sync="sform"  :cateTreeData="cateTreeData" :locationTreeData="locationTreeData" v-if="Object.keys(cateTreeData).length > 0"></SearchVue>
		<CommonAddVue ref="commoAddDialog"></CommonAddVue>
		<ImportVue ref="importDialog"></ImportVue>
	</div>
</template>

<script>
	import AddVue from "./Add.vue"
	import SearchVue from "./Search.vue"
	import CommonAddVue from "../Common/Add.vue"
	import ImportVue from "./ImportAsset.vue"
	export default { 
		inject: ["reload"],
		components: {
			AddVue,
			SearchVue,
			CommonAddVue,
			ImportVue
		},
		data() {
			return {
				formLabelWidth: '80px',
				batchStatus:false,
				sform:[],
				dialog: false,
				checkAll: false,
				isIndeterminate: true,
				fieldFloatingStatus: false,
				defaultCheckedKeys: ['id'],
				tableData: [],
				multipleData:[],			
				form: {
					name: ''
				},
				currentPage: 1,
				pagesize: 10,
				total: 0,
				cateTreeData: [], //分类数据
				locationTreeData: [], //位置数据
				data: [],
				theadData: [],
				loading:true,
				maxH:0,
				headerCellStyle: function({
					row,
					column
				}) {
					console.log(row)
				}
			}
		},
		created() {
			this.getTree()
			this.getData()
		
		},
		mounted() {

		},
		methods: {
			handleStatus() {

			},
			
			 getErrMsg(showStatus,row,type){
				// var showStatus =  true
				var errMsg = ''
				if((type == 4 || type == 2 || type == 7) && row.status !== 1){
					errMsg = '空闲下的资产才可操作'
					showStatus = false 
				}
				if((type == 3 || type == 6) && row.status !== 12){	//退库/变更操作
					errMsg = '在用的资产才可操作'
					showStatus = false
				}
				if(type == 5  && row.status !== 4){		//归还操作
					errMsg = '借出中的资产才可操作'
					showStatus = false
				}				
				return {status:showStatus,msg:errMsg}	
			},
			//操作弹窗
			handleCommonAdd(rows,storage_type,card_name){
				this.$api.post('/AssetDiscovery/check',{data:rows},res=>{
					if(res.code == 200){		//判断操作资产是否在盘点中
						this.$nextTick(()=>{
							this.$refs.commoAddDialog.storageType = storage_type
							this.$refs.commoAddDialog.cardName =  card_name
							this.$refs.commoAddDialog.tableData = []
							var showStatus =  true
							// var errMsg = ''
							var errRes = {}
							if(rows instanceof Array){
								var status = []
								rows.forEach(item=>{
									if(showStatus){
										errRes =  this.getErrMsg(showStatus,item,storage_type)
										showStatus = errRes['status']
									}
								})
								this.$refs.commoAddDialog.tableData  = rows
							}else{	
								errRes =   this.getErrMsg(showStatus,rows,storage_type)
								showStatus =  errRes['status']
								this.$refs.commoAddDialog.tableData.push(rows)
							}
							
							if(errRes['status']){
								this.$refs.commoAddDialog.handleAdd()
							}else{
								this.$message.error(errRes['msg'])
								return false
							}
							
						})
					}else{
						this.$message.error(errRes['msg'])
					}
				},fail=>{
					this.$message.error(fail.msg)
				})
				
			},
			handleSelectionChange(val){
				this.multipleData = val
				if(this.multipleData.length > 0){
					this.batchStatus = true
				}else{
					this.batchStatus = false
				}
				// console.log('handle-selection:',val)
			},
			//导出资产清单
			handleExport(){
				location.href = '/api/admin/AssetList/export'
			},
			
			//导出标签
			handleExportLabel(){
				var id = []
				if(this.multipleData.length > 0){
					this.multipleData.map(item=>(
						id.push(item.id)
					))
				}
				
				 location.href = '/api/admin/assetList/exportLabel?id='+id
			},
			
			handleImport(){
				this.$refs.importDialog.getData()
				// this.$refs.importDialog.dialogImportVisible =  true
			},
			//删除资产
			handleDel(rows) {
				var id = []
				if(rows instanceof Array){
					if(this.multipleData.length > 0){
						this.multipleData.map(item=>(
							id.push(item.id)
						))
					}
				}else{
					id.push(rows.id)
				}
				this.$alert("确定删除该资产吗？", "删除提示", {
				    showConfirmButton: true,
				    showCancelButton: true,
				    callback: action => {
				        if (action == "confirm") {
				            this.$api.delete("AssetList/del", { "id": id }, res => {
				                if (res.code == 200) {
				                    this.$message.success(res.msg);
				                    this.reload();
				                }
				            },fail=>{
								 this.$message.success(fail.msg);
							});
				        }
				        else if (action == "cancel") {
				            this.reload();
				        }
				    }
				});
			},
			//更多操作
			showMore(index, rows) {
			 rows[index]['more_status'] = !rows[index]['more_status']
				this.$set(rows, index, rows[index])
			},
			
			//监听显示列复选框
			handleTreeCheck(node, type, children) {
				var checked_data = this.$refs.rowTree.getCheckedKeys() //被选中的列
				this.defaultCheckedKeys = checked_data
				// 比对选中列 对表头进行更
				this.theadData = []
				for (let i = 0; i < this.data.length; i++) {
					for (let j = 0; j < checked_data.length; j++) {
						if (this.data[i]['field_name'] == checked_data[j]) {
							this.theadData.push(this.data[i])
						}
					}
				}
			},
			//拖拽成功触发事件
			handleDrop(draggingNode, dropNode, dropType, ev) {
				this.theadData = []
				this.$refs.rowTree.setCheckedKeys(this.defaultCheckedKeys) //重新写入选中数据
				this.$nextTick(() => {
					this.theadData = this.data

				})
			},
			handleColumn() {
				this.fieldFloatingStatus = !this.fieldFloatingStatus
			},
			allowDrop(draggingNode, dropNode, type) {
				// console.log(this.defaultCheckedKeys)
				if (type !== 'inner') {
					return true
				} else {
					return false
				}
			},
			getTree() {
				this.$api.get('AssetCategory/treeData',null, res => {
					if (res.code == 200) {
						this.cateTreeData = res.data.tree_list
					}
				})
				this.$api.post('AssetLocation/treeData',null, res => {
					if (res.code == 200) {
						this.locationTreeData = res.data.tree_list
					}
				})
			
			},

			getData() {
				this.form['page'] = this.currentPage-1
				this.form['limit'] = this.pagesize
				
				if(this.sform){	//高级搜索
					this.form['data'] = this.sform
				}
			
				this.$api.post('AssetList/index', this.form, res => {
					console.log(res)
					if (res.code == 200) {
						this.tableData = res.data.list ?? []
						this.total = res.data.count
						this.theadData = res.data.thead
						this.data = res.data.thead
						this.data.map(item => (this.defaultCheckedKeys.push(item.field_name)))
					}else{
						this.$message.error(res.msg)
					}
					this.loading =  false
				})
			},
			handleAdd(id = 0,row = {}) { 
				if(row.status && row.status !== 1){ 
					this.$message.error('空闲中的资产才可操作')
					return false
				}
				// this.$api.get('admin/deptList',null,res=>{
				//     if(res.code == 200){
				// 		var deptList =  res.data							
				// 		this.$refs.childDialog.handleAdd(id, this.cateTreeData, this.locationTreeData,deptList)
				// 	}
				// })
				this.$api.get('Department/deptList',null,res2=>{
					if(res2.code == 200){
						this.$refs.childDialog.handleAdd(id, this.cateTreeData, this.locationTreeData,res2.data.list)
					}  
					  
				}) 
			},
			
			handleSearch(){
				this.$refs.searchDialog.searchDialog()
			},
	
			getCurrPage(val) { //获取当前页
				this.currentPage = val
				this.loading = true
				this.getData()
			},
			getPageSize(val) { //获取每页条数
				this.pagesize = val
				this.getData()
			},
		},
	}
</script>
<style lang="scss">
	.floating-box {
		.el-tree-node__content {
			height: 35px;
			line-height: 35px;
			font-size: 14px;
			font-weight: bold;
		}
	}
	.more-box{
		.btn{
			margin: 3px 2px;
		}
	}
</style>
<style scoped lang="scss">
	.floating-box {
		width: 200px;
		// background: red;
		min-height: 200px;
		position: absolute;
		z-index: 999;
		background: white;
		right: 10px;
		top: 30px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		padding: 5px;

		.el-icon-sort {
			position: absolute;
			right: 0px;
			color: #909399;
		}

		// .el-checkbox{
		// 	width: 200px;
		// 	display: block;
		// 	line-height: 30px;
		// 	.el-icon-sort{
		// 		position: absolute;
		// 		right: 5px;
		// 	}
		// }
	}
</style>
