<template>
<div>
	<el-drawer title="高级搜索" :visible.sync="dialog" custom-class="demo-drawer" ref="drawer" size="20%">
		<el-form class="asset-form" label-position="top"     ref="form" prop=""  style="padding: 0px 25px;">
			<div v-for="(form,formIndex) in form ">
				 <el-form-item   :label="form.title" :prop="form.field_name">
					<!-- 单行文本 -->
					 <el-input  v-if="form.type < 1 "  v-model="form.value"></el-input>
					 <!-- 所在位置 -->  
					 <el-select style="width: 100%;" v-if="form.type == 9 && form.field_name == 'location_id'" ref="locationTreeSelect"  v-model="location_name" placeholder="请选择" clearable  @change="handleLocationSelect">
						<el-option value="0" label="无上级">无上级</el-option>
						<el-tree   node-key="id" :data="locationData" :props="treePorps" highlight-current ref="tree" 
							@node-click="(val)=>handleLocationNodeClick(val,formIndex)"> </el-tree>
					 </el-select>
					 <!-- 资产分类 -->
					<el-select style="width: 100%;"  v-if="form.type == 9 && form.field_name == 'cate_id'"   ref="treeSelect" v-model="cate_name" placeholder="请选择" clearable  @change="handleSelect">
						<el-option value="0" label="无上级"></el-option>
						<el-tree node-key="id" :current-node-key="form.pid" :data="cateData" :props="treePorps" highlight-current ref="tree"
							@node-click="(val)=>handleNodeClick(val,formIndex)"> </el-tree>
					</el-select>
					<!-- 下拉框 -->
					<el-select style="width: 100%;"  v-if="form.type == 9 && form.field_name !== 'cate_id' && form.field_name !== 'location_id'" v-model="form.value" clearable placeholder="请选择">
						<el-option v-for="(selectItem,selectIndex) in form.content" :key="selectIndex" :value="selectIndex" :label="selectItem.name"></el-option>
					</el-select>
					<!-- 日期 -->
					<el-date-picker
						v-if="form.type == 6"
						v-model="form.value"
						type="daterange"
						range-separator="至"
						placeholder="选择日期"
						start-placeholder="开始日期"
						end-placeholder="结束日期"	>
					</el-date-picker>
					<!-- 多行文本 -->
					<el-input type="textarea" v-if="form.type == 1"   v-model="form.value" placeholder=""></el-input>
				</el-form-item>
			</div>
			</el-row>
		</el-form> 
		<div style="width: 100%; text-align:center;">
			<el-button @click="reset">重 置</el-button>
			<el-button type="primary" @click="confirm()">查 询</el-button>
		</div>
	</el-drawer>
	
</div>
</template>

<script>
export default {
	 props:['cateTreeData','locationTreeData','sform'],
    data() {
        return {
			dialog:false,
			form:[],	
			cate_id: '',	//分类id
			cate_name:'',
			location_id:'',		//资产位置
			location_name:'',
			cateData:this.cateTreeData,
			locationData:this.locationTreeData,
			treePorps: {
				label: "name"
			}
        }
    },
    created() {
		
    },
    mounted() {
		
    },
    methods:{
		searchDialog(){
			console.log(this.locationData)
			this.$api.get('AssetList/index', null, res => {
				if (res.code == 200) {
					this.form  = res.data.group_data
					this.dialog =  true
				} 
			})	
		},
		confirm(){
			this.$emit('update:sform',this.form)
			this.dialog =  false
			this.$nextTick(()=>{
				this.$parent.getData()
			})
		},
		reset(){
			
		},
		handleSelect(val){
			this.cate_id =  val
		},
		handleLocationSelect(val){
			this.location_id = val
		},
		handleNodeClick(data,index){
			this.cate_name = data.name
			this.cate_id = data.id
			this.form[index]['value'] = data.id
		},
		handleLocationNodeClick(data,index){
			this.location_name = data.name
			this.location_id = data.id
			this.form[index]['value'] = data.id
		},
    },
}
</script>
<style scoped>

</style>